<template>
  <div techSupport>
    <visual3
        v-if="active === 'policy'" key="policy"
        tit="<i class='kv-logo'></i>"
        txt='<div class="font40">“Tech Support”는<br class="mo"> 고객사의 건설 제품 품질 고도화를 위한<br>현대제철의<b> 제작 및 시공 가이드</b>와 <br class="mo"><b>지속적인 현장 기술지원</b>체제입니다.</div>'
        bg="tech-kv"
    />
    <visual3
        v-if="active === 'requestTechnicalSupport'" key="requestTechnicalSupport"
        mo="mo"
        txt='현대제철은 건설 산업 <b>고객사와의 동반성장</b> <br class="mo"> 동반성장과 미래 건설기술의 발전을 도모하고자 <p class="font50">여러분의<b>우수한 아이디어</b>를 기다리고 있습니다.</b>'
        round="아이디어 지원하기"
        bg="tech-kv"
    />
    <Tab :list="selectList" :active="active" @select="select"/>
    <article>
      <Paragraph class="content policy" v-if="active === 'policy'" key="policy">
        <div class="cnt-inner">
          <h3 class="para">
            TECH SUPPORT
          </h3>
          <ul  class="cont para sol-list">
            <li  v-for="(list, idx) in contList01"
                 :key="list.idx"
                 :class="'list01-item' + (idx + 1)">
              <div class="img web">
                <img :src="'/images/pc/tech01_0' + (idx +1) + '.jpg'" alt="">
              </div>
              <div class="img mo">
                <img :src="'/images/mo/tech01_0' + (idx +1) + '.png'" alt="">
              </div>
              <p class="tit" v-html="list.tit"></p>
              <p class="txt" v-html="list.txt"></p>
            </li>
          </ul>
        </div>
      </Paragraph>
      <Paragraph class="content request" v-if="active === 'requestTechnicalSupport'" key="requestTechnicalSupport">
        <div class="cnt-inner">
          <h3 class="para">
            요청 기본 정보
          </h3>
          <div class="contact-form03 para">
            <div class="form-list">
              <div class="list">
                <div class="tit">
                  <p>
                    고객사 정보
                  </p>
                </div>

                <div class="row-wrap">
                  <div class="row">
                    <p class="label">고객사명</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.institute">
                  </div>
                  <div class="row">
                    <p class="label">업종</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.business">
                  </div>
                </div>
              </div>
              <div class="list">
                <div class="tit">
                  <p>
                    담당자
                  </p>
                </div>

                <div class="row-wrap">
                  <div class="row">
                    <p class="label">성명</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.name">
                  </div>
                  <div class="row">
                    <p class="label">직위/직급</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.position">
                  </div>
                  <div class="row">
                    <p class="label">부서</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.department">
                  </div>
                  <div class="row">
                    <p class="label">전화번호</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.phone">
                  </div>
                  <div class="row">
                    <p class="label">Email</p>
                    <input type="text" class="email" placeholder="입력해 주세요" v-model="param.email">
                  </div>
                  <div class="row">
                    <p class="label">주소</p>
                    <input type="text"  placeholder="입력해 주세요" v-model="param.address">
                  </div>
                </div>
              </div>
              <div class="list">
                <div class="tit">
                  <p>
                    자문 요청 정보
                  </p>
                </div>
                <div class="row-wrap">
                  <div class="row">
                    <p class="label">요청명</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.requestName">
                  </div>
                  <div class="row">
                    <p class="label">요청분야</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.field">
                  </div>
                  <div class="row h-auto">
                    <p class="label">요청상세내용</p>
                    <textarea v-model="param.detail" placeholder="입력해주세요"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-wrap">
              <div class="desc">첨부파일 (양식자유)</div>
              <a class="button" @click="fileAttech">찾아보기</a>
            </div>
            <ul class="b-list">
              <li>
                <div class="name" id="fileNmae"></div>
                <input style="display: none" type="file" ref="fileInput" @change="fileChange"/>
                <button type="button" @click="deleteFile"><i class="icon-x"></i></button>
              </li>
            </ul>
            <div class="b-list">
              <input id="agreeVal" ref="agreeVal" type="hidden" value="0" >
              <div class="check-box" id="noCheck" @click="AgreePolicyPopOpen"></div>
              <div class="check-box" id="yesCheck" style="display: none" @click="AgreePolicyPopOpen"><img src="/images/pc/checkbox_check.svg"></div>
              <p class="check-text" @click="AgreePolicyPopOpen">개인 정보 제공 및 활용에 동의합니다.</p>
            </div>
            <a class="submit" @click="submit">지원서 최종 제출</a>
          </div>
        </div>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual3 from "@/views/components/Visual3";
import Tab from "@/views/components/Tab";
import Paragraph from "@/views/components/Paragraph";
import techSupport from "@/data/techSupport";
import axios from "axios";
import AgreePolicyPopOpen from "@/views/components/modal/AgreePivacyModal.vue";

export default {
  name: "TechSupport",
  components: { Visual3, Paragraph, Tab },
  data() {
    return {
      slideImgPath: "/images/pc/product-prd",
      winWidth: 0,
      param: {
        institute: '',
        address:'',
        department:'',
        name: '',
        phone: '',
        email: '',
        requestName:'',
        field:'',
        business:'',
        position:'',
        detail:'',
      }
    };
  },
  computed: {
    active() {
      return this.$route.params.techSupport
    },
    selectList() {
      return [
        {key: 'policy', label: 'policy'},
        //{key: 'requestTechnicalSupport', label: 'Request Technical Support'}
      ]
    },
    contList01(){
      return techSupport.techCont1;
    },
  },
  methods: {

    select(techSupport) {
      if (this.active === techSupport) return;
      this.$router.push({ params: { techSupport } })
    },

    fileAttech(){
      this.$refs.fileInput.click();
    },

    fileChange(){
      const fileData = this.$refs.fileInput.files[0];
      document.getElementById("fileNmae").innerText = fileData.name;
    },

    deleteFile(){
      this.$refs.fileInput.value = null;
      document.getElementById("fileNmae").innerText = null;
    },

    AgreePolicyPopOpen() {
      this.$modal(AgreePolicyPopOpen);
    },

    async submit() {

      if(this.$refs.agreeVal.value != 1){
        alert('개인 정보 제공 및 활용에 동의해 주십시요.');
        return;
      }

      if (!this.param.institute) {
        alert('고객사명 을(를) 입력해주세요');
        return;
      }
      if (!this.param.business) {
        alert('업종 을(를) 입력해주세요');
        return;
      }
      if (!this.param.name) {
        alert('성명 을(를) 입력해주세요');
        return;
      }
      if (!this.param.position) {
        alert('직위/직급 을(를) 입력해주세요');
        return;
      }
      if (!this.param.department) {
        alert('부서 을(를) 입력해주세요');
        return;
      }
      if (!this.param.phone) {
        alert('전화번호 을(를) 입력해주세요');
        return;
      }
      if (!this.param.email) {
        alert('이메일 을(를) 입력해주세요');
        return;
      }
      if (!this.param.address) {
        alert('주소 을(를) 입력해주세요');
        return;
      }
      if (!this.param.requestName) {
        alert('요청명 을(를) 입력해주세요');
        return;
      }
      if (!this.param.field) {
        alert('요청분야 을(를) 입력해주세요');
        return;
      }
      if (!this.param.detail) {
        alert('요청상세내용 을(를) 입력해주세요');
        return;
      }

      const formData = new FormData();
      formData.append("institute",this.param.institute);
      formData.append("business",this.param.business);
      formData.append("name",this.param.name);
      formData.append("position",this.param.position);
      formData.append("department",this.param.department);
      formData.append("phone",this.param.phone);
      formData.append("email",this.param.email);
      formData.append("address",this.param.address);
      formData.append("requestName",this.param.requestName);
      formData.append("field",this.param.field);
      formData.append("detail",this.param.detail);
      formData.append("fileMail", this.$refs.fileInput.files[0]);

      await axios.post("/dist/techSupportMail", formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          //await axios.post("/dist/sendMail", formData)
          .then((response) => {
            alert("response : " + response.data.message);
            //alert(response.message);
            //alert("메일이 성공적으로 발송되었습니다 : " + response);
            console.log("response.message : "+response.message)
          }).catch((error) => {
            alert("에러가 발생하였습니다 : " + error);
            //alert("error : " + error);
            //alert(error.message);
          });
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[techSupport] {
  article {
    .bgc(#fff);
    .rel;
    .crop;
    .para { transform: translateY(100px); opacity: 0; transition: transform 0.3s, opacity 0.3s; transition-timing-function: ease-out;
      &.on { transform: translateY(0); opacity: 1; }
    }
    .content{
      .p(188,50); .-box; .clear;
    }
    .cnt-inner {
      .-box;
      .rel;
      z-index: 1;
      & + .cnt-inner{
        .pt(214);
      }
    }

    .policy {
      h3 { .fs(40); .lh(40); .bold; .mb(26); .mt(-63);}
      .cnt-wrap { .p(80,0); .-box; }
      .sol-list {
        .flex;
        .space-between;
        .flex-wrap;
        .pt(0);
        .rel;
        gap: 32px 10px;
        li {
          .wh(calc(((100% - 10px) / 2) ),auto);
          .crop();
          position: relative;
          .img{
            .wh(100%, 460);
            .crop();
            img { .wf; transition: transform 0.5s; }
            &:hover img { transform: scale(1.03); }
          }
        }
      }
      .tit {
        .block();
        .mt(30);
        .fs(28);
        .lh(35.06);
        .spoqa;
        .bold;
        .c(#0075C1)
      }
      .txt {
        .block();
        .mt(30);
        .fs(20);
        .lh(30);
        .ls(-0.05em);
        .spoqa;
        .c(#333)
      }
    }

    .request{
      h3 { .fs(40); .lh(40); .bold; .mb(26); .mt(-63);}
      .contact-form03{
        .w(100%);
        .-box;
        .form-list{
          .list{
            .flex();
            .flex-wrap;
            .flex-column;
            height: 100%;
            //align-items: center;
            .tit{
              display: flex;
              flex: 1 31px;
              .w(100%);
              .crop();
              .mt(0);
              .-a(#137CC2);
              .-box();
              flex-direction: column;
              justify-content: center;
              .bgc(#E9EFF9);
              .-r(#137CC2);
              .tl;
              p{
                .c(#0875C1);
                .fs(24);
                .lh(24);
                .bold;
                .pl(10);
              }
            }

            .row-wrap{
              flex: 1 0 auto;
              .w(100%);
              p{
                flex: 0 97px;
                .h(60);
                .min-w(194);
                .pl(10);
                .c(#0875C1);
                .-r(#137CC2);
                .-l(#137CC2);
                .-b(#137CC2);
                .-box;
                .bgc(#E9EFF9);
                .tl;
                .fs(24);
                .lh(60);
                .vam();
                .bold;
              }
            }
            .row{
              .flex();
              flex: 1;
              .-r(#b1b1b1);
              input {
                .h(60);
                .lh(60);
                .ib;
                .vam;
                .pl(20);
                .pr(20);
                .-box;
                .-a(none);
                .fs(16);
                .spoqa;
                .-box;
                .-b(#b1b1b1);
                .c(#555555);
                flex: 1;
                &::placeholder {
                  .fs(16);
                  .c(#bbb);
                }
                &.email{
                  .c(#0875c1);
                }
              }
              &.h-auto{
                .h(90);
                p{
                  .lh(90);
                  .h(90);
                }
                textarea {
                  .p(11,20);
                  .min-h(90);
                  .fs(14);
                  .spoqa;
                  .-box;
                  .-b(#b1b1b1);
                  .c(#555555);
                  flex: 1;
                  &::placeholder {
                    .fs(14);
                    .c(#bbb);
                  }
                  &.email{
                    .c(#0875c1);
                  }
                }
              }

              &:last-of-type{
                input {
                  .-b(none);
                }
                .label{
                  .-b(none);
                }
              }
            }
            &:last-of-type{
              .row-wrap{
                .row:last-of-type{
                  p{
                    .-b(#137CC2);
                  }
                  input{
                    .-b(#B1B1B1);
                  }
                }
              }
            }
          }
        }
      }
      .button-wrap{
        .desc{
          .fs(24);
          .mt(30);
        }
        .button{
          .mt(20);.wh(99,60); .fs(16); .lh(58);.tc; .spoqa; .-a(#333); .-box; .ib; transition: background-color 0.3s, transform 0.5s, opacity 0.5s; .ease-out;
          &:hover { .bgc(#153347); color:#fff;}
        }
      }
      .b-list{
        .mt(12);
        .check-box{ .wh(27,27) !important; .ib; .-a(#d1d5db); .fl; .mr(15); .pointer;}
        .check-text{ .underline; .pointer; .fs(20); }
        li{
          .flex;
          .space-between;
          .-box;
          &+ li{
            .mt(20);
          }
          .w(100%);
          .h(60);
          .-b();
          .-a(#333);
          .p(20);
          .name{
            .fs(14);
            .lh(20);
            .c(#000);
            .spoqa;
            &:before{
              content:'';
              display:inline-block;
              .wh(20,20);
              background:url("/images/pc/file.svg") no-repeat 0 0/100%;
              background-size:cover;
              .mr(10);
              vertical-align: middle;
              transform: translateY(-1px);
            }
          }
        }
        button{
          .wh(20,20);
          .bgc;
          .icon-x{
            display: block;
            width: 100%;
            height: 100%;
            background:url("/images/pc/x.svg") no-repeat 0 0/100%;
            background-size:cover;
          }
        }
      }
      .submit { .fs(24); .tc; .wh(250,60);  .lh(60); .bgc(#0d1112); .block; color:#fff; .m(40, auto ,0);
        &:hover { .bgc(#153347); color:#fff;}
      }
    }
  }
}
@media screen and(min-width: 1024px) {
  [techSupport] {
    article {
      .content {
        .p(170,0,350);
        .max-w(1400);
        .mh-c;
        .cnt-inner {
          & + .cnt-inner{
            .pt(172);
          }
          & + .cnt-inner.ty2{
            .pt(212);
          }
        }
        &.policy {
          h3 { .fs(40); .lh(40); .bold; .mb(26); .mt(-63);}
          .cnt-wrap { .p(80,0); .-box; }
          .sol-list {
            .flex; .space-between; .flex-wrap;
            .pt(0); .rel;
            li {.wh(calc(((100% - (20px * 2)) / 3) ),auto); .crop();
              position: relative;
              .img{
                .wh(100% , 490px);
                .crop();
              }
              img { .hf; transition: transform 0.5s; }
              &:hover img { transform: scale(1.03); }
              &.list01-item:after {
                .cnt;
                .wh(375,414);
                .contain("/images/mo/prd-cont1.png");
                .abs;
                .rt;
              }
            }
          }
          .tit {
            .block();
            .mt(30);
            .fs(28);
            .lh(35.06);
            .spoqa;
            .bold;
            .c(#0075C1)
          }
          .txt {
            .block();
            .mt(30);
            .fs(20);
            .lh(30);
            .ls(-0.05em);
            .spoqa;
            .c(#333)
          }
        }
        &.request{
          .contact-form03{
            .w(97.2%);
            .p(0,80);
            .-box;
            .form-list{
              .-t(#137CC2);
              .-b(#137CC2);
              .list{
                .flex();
                .flex-wrap;
                .flex-row;
                .flex-ai(normal);
                .-t(#137CC2);
                .-b(#137CC2);
                height: 100%;
                //align-items: center;
                .tit{
                  display: flex;
                  flex: 0 171px;
                  //.h(100%);
                  .h(auto);
                  .crop();
                  .mt(0);
                  flex-direction: column;
                  justify-content: center;
                  .bgc(#E9EFF9);
                  .-a(none);
                  .-r(#137CC2);
                  .tc;
                  p{
                    .p(0);
                    .c(#0875C1);
                    .fs(16);
                    .lh(20.03);
                    .bold;
                    //align-self: center;
                  }
                }

                .row-wrap{
                  //flex: 1 0 auto;
                  flex: 0 calc(100% - 171px);
                  //height: 40px;
                  p{
                    flex: 0 171px;
                    .p(0);
                    .h(40);
                    .c(#0875C1);
                    .-r(2px, #137CC2);
                    .-b(#137CC2);
                    .-box;
                    .bgc(#E9EFF9);
                    .tc;
                    .fs(16);
                    .lh(40);
                    .vam();
                    .bold;
                  }
                }
                .row{
                  flex: 1 0  calc(100% - 171px);
                  .h(40);
                  .flex();

                  input, .select {.h(40); .lh(40); .ib; .vam; .pl(20); .pr(20); .-box;  border:none;}
                  input {
                    .fs(14);
                    /* .w(80%);*/
                    .spoqa;
                    .-box;
                    .-b(#b1b1b1);
                    .c(#555555);
                    flex: 1;
                    &::placeholder {
                      .fs(14);
                      .c(#bbb);
                    }
                    &.email{
                      .c(#0875c1);
                    }
                  }
                  &.h-auto{
                    .h(90);
                    p{
                      .lh(90);
                      .h(90);
                    }
                    textarea {
                      .p(11,20);
                      .min-h(90);
                      .fs(14);
                      .spoqa;
                      .-box;
                      .-b(#b1b1b1);
                      .c(#555555);
                      flex: 1;
                      &::placeholder {
                        .fs(14);
                        .c(#bbb);
                      }
                      &.email{
                        .c(#0875c1);
                      }
                    }
                  }

                  &:last-of-type{
                    input {
                      .-b(none);
                    }
                    .label{
                      .-b(none);
                    }
                  }
                }
              }

            }
          }
          .button-wrap{
            .button{
              .wh(206,34);
              .fs(14);
              .lh(34);
              .tc;
            }
          }
          .b-list{
            .mt(12);
            li{
              .flex;
              .space-between;
              .-box;
              &+ li{
                .mt(20);
              }
              .w(100%);
              .h(40);
              .-b();
              .-a(#333);
              .p(11,20);
              .name{
                .fs(14);
                .lh(17.53);
                .c(#000);
                .spoqa;
                &:before{
                  content:'';
                  display:inline-block;
                  .wh(18,18);
                  background:url("/images/pc/file.svg") no-repeat 0 0/100%;
                  background-size:cover;
                  .mr(10);
                  vertical-align: middle;
                  transform: translateY(-1px);
                }
              }
            }
            button{
              width: 18px;
              height: 18px;
              .wh(18,18);
              .bgc;
              .icon-x{
                display: block;
                width: 100%;
                height: 100%;
                background:url("/images/pc/x.svg") no-repeat 0 0/100%;
                background-size:cover;
              }
            }
          }
        }
      }
    }
  }
}


</style>