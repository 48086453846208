<template>
  <div visual3 :class="{ready}" :style="{height: this.mainKvH}">
    <div class="kv" :class="bg"></div>
    <div class="kv-inner">
      <p class="logos"  :class="mo"></p>
      <h2 v-html="tit"></h2>
      <p class="txt" v-html="txt"></p>
      <p class="round" v-html="round"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Visual3",
  props:{
    tit: { type: String, default: null },
    txt: { type: String, default: null },
    bg: { type: String, default: null },
    round : {type: String, default: null},
    mo: {type: String , default: null}
  },
  data() {
    return {
      ready: true,
      scrollPosition: null,
      dir: null,
      mainKvH: '100vh',
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() =>{ this.ready = false },500);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.scrollPosition = document.documentElement.scrollTop || 0;
    this.mainKvHeight();
  },
  watch: {
    $route(to, from) {
      if(to.path !== from.path) {
        this.$router.go(this.$router.currentRoute);
      }
    }
  },
  methods: {
    handleScroll() {
      let docY = document.documentElement.scrollTop;
      this.dir = docY - this.scrollPosition >= 0 ? 1 : -1;
      this.scrollPosition = docY
      this.kvToggle();
    },
    kvToggle() {
      if(this.dir === 1) { this.ready = true; }
      if(this.scrollPosition < 10) { this.ready = false; }
    },
    mainKvHeight() {
      if(window.innerWidth < 768) {
        this.mainKvH = window.innerHeight + 'px';
      } else if(window.innerWidth > 1024) {
        this.mainKvH = (window.innerHeight + 37)+ 'px';
      } else if(window.innerWidth > 1400) {
        this.mainKvH = (window.innerHeight + 22)+ 'px';
      }
    },
    handleResize() {
      this.mainKvHeight();
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[Visual3] { .h(100vh); .rel;
  .kv { .wh(100%,100vh); .fix; .lt; .cover; transition: transform 1s; transform: scale(1); .ease-out; .mt(120);
    &.brand-kv { background: url("/images/mo/brand-kv.png")no-repeat center center; }
    &.product-kv { background: url("/images/mo/product-kv.png")no-repeat center center; }
    &.solution-kv {background: url("/images/mo/solution-kv.png") no-repeat center center/cover;}
    &.solution-kv02 {background: url("/images/mo/solution-kv02.png") no-repeat center center/cover;}
    &.tech-kv {background: url("/images/mo/tech-kv.png") no-repeat center center/cover;}
    &.tech-kv02 {background: url("/images/mo/tech-kv02.png") no-repeat center center/cover;}
  }
  .kv-inner {
    .abs; .l(0); .rt(0, 50%); transform:translateY(-50%); .w(calc(100% - 75px)); transition: opacity 0.5s; .tl; .c(#fff);
    z-index: 1; .p(56,20,56,80);
    &:after { .cnt; .wh(100%, 100%); .bgc(#000); .abs; .lt(50%,50%); transform: translate(-50%,-50%); z-index: -1; .o(0.5); transition: opacity 0.7s; }
    .kv-logo { .wh(226,114); .contain('/images/mo/logo-w3.svg'); .ib; .vam; }
    .logos{
      &.mo{
        display: block;
        .wh(226,114); .contain('/images/mo/logo-w3.svg'); .ib; .vam;
      }
    }
    p {.mt(0); .fs(30); .lh(42); .regular; .ggo; transition-delay: 0.3s;
      b { .bold; }
      & > .font40 , & > .font50{
        .fs(30);
      }
    }
    .round{
      &:empty{
        display: none;
      }
      .abs();
      .b(-86);
      .l(0);
      .p(17,85);
      .bgc(#fff);
      .ggo;.fs(24);
      .lh(26.4);
      .br(100);
      .-box();
      .c(#333);
      .bold();
    }
  }
  h2{
    &:empty{
      display: none;
    }
  }
  h2, p, .sd { transform: translateY(0); transition: transform 1s, opacity 0.5s; .ease-out; }
  &.ready {
    .kv { transform: scale(1.2); }
    h2, p, .sd { transform: translateY(100px); opacity: 0; }
    .kv-inner{
      &:after { .o(0) }
    }
  }
}

@media screen and(min-width:1024px) {
  [Visual3] { .rel;
    .kv { .mt(0);
      &.brand-kv { background: url("/images/pc/brand-kv.png")no-repeat center center; .cover; }
      &.product-kv { background: url("/images/pc/product-kv.png")no-repeat center center; .cover; }
      &.solution-kv{background:url("/images/pc/solution-kv.jpg") no-repeat center center; .cover;}
      &.solution-kv02{background:url("/images/pc/solution-kv02.png") no-repeat center center; .cover;}
      &.tech-kv {background: url("/images/pc/tech-kv.png") no-repeat center center;  .cover;}
      &.tech-kv02 {background: url("/images/pc/tech-kv02.png") no-repeat center center; .cover;}
    }
    .product-kv + .kv-inner .txt .kv-logo { .wh(316,75); .mt(0); }
    h2 {
      .tc;
      &:empty{
        display: none;
        & + .txt{
         // .mt(27px);
        }
      }
      & + .txt{
        .mt(100);
      }
    }
    .kv-inner {
      .flex();
      .flex-column();
      .justify-center;
      .max-w(1400);
      .min-h(430);
      .l(50%);
      .r(auto);
      transform: translate(-50%,-50%);
      .p(53, 0);
      .-box;
      .tc;
      &:after {
        .cnt;
        .bgc(#000);
        .abs;
        .lt(50%,50%);
        transform: translate(-50%,-50%);
        z-index: -1;
        .o(0.5);
        transition: opacity 0.7s;}
      .kv-logo {
        .wh(409.5,80);
        .cover('/images/pc/logo-w3.svg');
        .ib;
        .vam;
        .tc;
      }
      p {
        width:auto;
        .fs(32);
        .lh(72);
        .tc;

        h2 + p{
          .mt(80);
        }
        & > .font40{
          .fs(40);
        }
        & > .font50{
          .mt(0);
          .fs(50);
        }
      }
      .round{
        position:static;
        display: inline-block;
        .m(40, auto ,0);
        .p(17,85);
        .bgc(#fff);
        .ggo;.fs(24);
        .lh(26.4);
        .br(100);
        .-box();
        .c(#333);
        .bold();
        .bold();
      }
      .logos{
        display: none;
        &.mo{
          display: none;
        }
      }
    }

    &.ready {
      .kv { transform: scale(1.1); }
      .kv-inner{
        &:after { .o(0) }
      }
    }
  }
}

</style>