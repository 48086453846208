<template>
  <Modal agree-modal>
      <h4>(필수)개인정보수집 및 이용동의</h4>
      <p>
        □ 개인정보의 수집 및 이용 동의 ㈜현대제철은 귀하께서 AE서비스포탈 홈페이지(https://ae.hyundai-steel.com/)의 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 간주합니다.<br/>
        <br/>
        ※ 본 서비스(사이트)는 만 14세 미만의 아동에 대한 개인정보를 수집하고 있지 않으며, 홈페이지에 아동에게 유해한 정보를 게시하거나 제공하고 있지 않습니다.<br/>
        <br/>
        AE서비스포탈 홈페이지는 Q&A 서비스 이용을 위해 필요한 최소한의 개인정보만을 수집합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행 할 예정입니다.<br/>
        <br/>
        Q&A, 귀하가 AE서비스포탈 홈페이지의 서비스를 이용하기 위해서 Q&A시 (국적, 이름, 이메일)를 필수적으로 입력하셔야 합니다. 그러나, 선택항목(회사)은 이를 입력하지 않더라도 서비스 이용에는 제한이 없습니다. 개인정보 항목별 구체적인 수집목적 및 이용목적은 다음과 같습니다.<br/>
        <br/>
        - 국적, 이름, 이메일 : 서비스 이용에 따른 본인 식별 절차에 이용 및 Q&A 답변시, 고지사항 전달, 불만처리 등을 위한 원활한 의사소통 경로의 확보 등에 활용<br/>
        - (선택)기타 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료<br/>
        <br/>
        □ 수집하는 개인정보 항목<br/>

        ① 필수 개인정보 항목 : 국적, 이름, 이메일<br/>

        ② 선택 개인정보 항목 : 회사<br/>
        <br/>
        □ 개인정보의 보유기간 및 이용기간 AE서비스포탈 홈페이지내 수집된 개인정보의 보유기간은 다음과 같습니다.<br/>

        삭제요청시 즉시 파기되며, 1년간 추가 Q&A가 없을시 파기합니다.<br/>

        파기 시 AE서비스포탈 홈페이지는 회원님의 개인정보를 재생이 불가능한 방법으로 즉시 파기하며, 다만 다음 각호의 경우에는 각 호에 명시한 기간 동안 개인정보를 보유합니다.<br/>

        ① 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유함<br/>

        ② 보유기간을 회원님에게 미리 고지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원님의 동의를 받은 경우에는 약속한 보유기간동안 보유함<br/>
        <br/>
        □ 동의 거부 권리 및 동의 거부 시 불이익 내용 귀하는 개인정보의 수집목적 및 이용목적에 대한 동의를 거부할 수 있으며, 동의 거부시 AE서비스포탈 홈페이지에 Q&A서비스는 이용할 수 없습니다.<br/>
      </p>

    <div class="items">
      <div class="btn-wrap">
        <button class="btn" @click="yesBtn">
          <span>동의</span>
        </button>
        <button class="btn" @click="noBtn">
          <span>미동의</span>
        </button>
      </div>
    </div>

  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
export default {
  name: "AgreePivacyModal",
  components: {Modal},
  data() {
    return {
    }
  },
  props : ['agreeVal'],
  methods: {
    handleCloseClick() {
      this.$emit('resolve');
    },
    yesBtn(){
      parent.document.querySelector('#yesCheck').style.display = "";
      parent.document.querySelector('#noCheck').style.display = "none";
      const agreeVal = parent.document.querySelector('#agreeVal');
      agreeVal.value = 1;
      this.$emit('resolve');
    },
    noBtn(){
      parent.document.querySelector('#yesCheck').style.display = "none";
      parent.document.querySelector('#noCheck').style.display = "";
      const agreeVal = parent.document.querySelector('#agreeVal');
      agreeVal.value = 0;
      this.$emit('resolve');
    },
  },
  mounted() {
    document.querySelector('.close').addEventListener('click', this.handleCloseClick);
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[agree-modal] {
  .inner { .t(120); .h(60vh)!important; .scroll; .bgc(#fff); .z(10); .rel;
    h4 {
      .fs(50);.lh(40);.ls(-0.025em);.bold; .w(90%); .tc; margin: auto; .c(#00488d); .mt(100); .mb(100);
    }
    p{
      .fs(20); .lh(40); .w(90%); .ib; .tl;
    }
    .items{
      .wh(90%, 50); .ib; .mv(50); .tc;
      .btn-wrap{
        .ib; .l(50%);
      }
      .btn{
        .rel; .wh(100, 40); .-a(#d1d5db, 1); .fl; .mr(10); .ib; .bgc(#00488d);
        span{ .fs(20); .c(#fff); }
      }
    }
  }
  .close-wrap{ .block !important; .wh(100%, 60); .rel; .mh(auto);
    .close{ .wh(50,50); .abs; .contain('/images/mo/pop-x.png');}
  }
}

@media (min-width: 1024px) and (max-width: 1399px){
  [agree-modal] { .fix;
    .inner { .mt(0)!important; .t(50%); .wh(1125,90vh)!important; .max-h(1000); transform: translate(-50%,-50%); .bgc(#fff); .z(10);
    }
    .close-wrap{ .block !important; .wh(100%, 60); .rel; .t(12%) !important; .mh(auto); }
  }
}

@media screen and(min-width:1400px) {
  [agree-modal] { .fix;
    .inner { .mt(0)!important; .t(50%); .wh(1125,90vh)!important; .max-h(1000); transform: translate(-50%,-50%); .bgc(#fff); .z(10);
    }

    .close-wrap{ .block !important; .wh(100%, 60); .rel; .t(5%) !important; .mh(auto); }
  }
}
</style>