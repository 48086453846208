export default {
  techCont1 : [
    {tit: '고객 맞춤형 기술지원', txt: '소재/설계/제작/시공 관련 문제에 대해<br> 실질적이며 체계적인 기술을 지원합니다.'},
    {tit: '교육 프로그램 운영', txt: 'H CORE 활용 고객사 대상 연 2회<br> 건설 전문 교육을 운영합니다.'},
    {tit: '건설 신기술 컨퍼런스', txt: '건설 전문가 컨퍼런스를 통해<br> 미래 건설 시장을 선도합니다.'},
  ],
}




